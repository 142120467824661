<template>
  <div class="contact-us-page">
    <div class="banner" />
    <div class="contact-us_blank">
      <div class="content-wrap">
        <div class="title">
          <div class="top"></div>
          <div class="bottom">联系我们</div>
        </div>
        <div class="contact-info">
          <div class="left">
            <div class="top">{{ configInfo.name }}</div>
            <div class="bottom">
              <div class="item">
                <img src="../assets/img/contact_address_icon.png"/>
                <div>
                  <span class="blank">地址</span>
                  <span class="info">{{ configInfo.address }}</span>
                </div>
              </div>
              <div class="item">
                <img src="../assets/img/contact_website_icon.png"/>
                <div>
                  <span class="blank">网址</span>
                  <span class="info">{{ configInfo.url }}</span>
                </div>
              </div>
              <div class="item">
                <img src="../assets/img/contact_phone_icon.png"/>
                <div>
                  <span class="blank">电话</span>
                  <span class="info">{{ configInfo.mobile }}</span>
                </div>
              </div>
<!--              <div class="item">-->
<!--                <img src="../assets/img/contact_email_icon.png"/>-->
<!--                <div>-->
<!--                  <span class="blank">邮箱</span>-->
<!--                  <span class="info">{{ configInfo.email }}</span>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
          <div class="right">
            <baidu-map
              class="map"
              :center="positionCenter"
              :zoom="zoom"
              :scroll-wheel-zoom="false"
            >
              <bm-marker
                :position="positionCenter"
                :dragging="false"
                @click="infoWindowOpen"
              >
                <bm-info-window
                  :show="show"
                  @close="infoWindowClose"
                  @open="infoWindowOpen"
                >
                  <p class="address-name">{{ configInfo.address_name }}</p>
                  <p class="address">校区地址：{{ configInfo.address }}</p>
                  <p class="map-tel">咨询电话：{{ configInfo.tel }}</p>
                </bm-info-window
                >
              </bm-marker>
            </baidu-map>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Utils from '@/utils/index';
export default {
  name: 'contactUs',
  data() {
    return {
      configInfo: {},
      positionCenter: {
        lat: '31.30273',
        lng: '121.51713'
      },
      zoom: 18,
      show: false
    };
  },
  mounted() {
    this.configInfo = Utils.getLocalStorage('config_info');
    this.positionCenter = {
      lat: this.configInfo.lat,
      lng: this.configInfo.lng
    };
  },
  methods: {
    /**
     * 关闭地图说明标签
     */
    infoWindowClose() {
      this.show = false;
    },
    /**
     * 打开地图说明标签
     */
    infoWindowOpen() {
      this.show = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.contact-us-page {
  width: 100%;
  .banner {
    width: 100%;
    height: 380px;
    background-image: url("../assets/img/contact_us_banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .contact-us_blank {
    margin: 73px 0 181px;
    .content-wrap {
      display: flex;
      flex-direction: column;
    }
    .title {
      margin-bottom: 61px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .top {
        width: 298px;
        background: url("../assets/img/contact_icon.png") no-repeat;
        background-size: 298px 47px;
        height: 47px;
        color: #EDEEEF;
        opacity: 0.83;
        font-size: 66px;
        line-height: 1;
      }
      .bottom {
        line-height: 1;
        font-size: 30px;
        color: #333333;
        z-index: 10;
        margin-top: -19px;
      }
    }
    .contact-info {
      display: flex;
      height: 514px;
      .left {
        display: flex;
        flex-direction: column;
        width: 600px;
        height: 514px;
        background: #2568D9;
        padding: 63px 0 0 63px;
        line-height: 1;
        .top {
          position: relative;
          font-size: 22px;
          font-weight: bold;
          color: #FFFFFF;
          padding-bottom: 26px;
        }
        .top:after {
          position: absolute;
          content: '';
          left: 0;
          bottom: 0;
          width: 33px;
          height: 4px;
          background: #FFFFFF;
        }
        .bottom {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-top: 40px;
          .item {
            display: flex;
            align-items: center;
            margin-bottom: 24px;
            img {
              width: 56px;
              height: 56px;
              margin-right: 13px;
            }
            div {
              display: flex;
              flex-direction: column;
              flex: 1;
              .blank {
                font-size: 18px;
                font-weight: 400;
                color: #FFFFFF;
                margin-bottom: 14px;
              }
              .info {
                font-size: 14px;
                color: rgba(255, 255, 255, 0.8);
              }
            }
          }
        }
      }
      .right {
        width: 600px;
        height: 514px;
        background: #fff;
        .map {
          width: 600px;
          height: 514px;
          .address-name {
            font-size: 16px;
            font-weight: 400;
            color: #000022;
            padding-bottom: 15px;
            border-bottom: 1px solid #F0F0F0;
          }
          .address {
            font-size: 12px;
            font-weight: 400;
            color: #888888;
            padding: 14px 0 16px;
            border-bottom: 1px solid #F0F0F0;
          }
          .map-tel {
            font-size: 12px;
            font-weight: 400;
            color: #888888;
            margin-top: 15px;
          }
        }
      }
    }
  }
}
</style>
